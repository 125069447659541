@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-custom-bg bg-custom-bg-image bg-repeat bg-[length:100%_auto] font-mplus font-medium;
  }

  h1 {
    @apply font-black text-3xl;
  }

  h2 {
    @apply font-extrabold text-2xl;
  }

  h3 {
    @apply font-bold text-xl;
  }

  p {
    @apply font-medium;
  }

  a {
    @apply text-black no-underline;
  }

  button {
    @apply w-full;
  }

  a,
  button {
    @apply transition-all duration-300 ease-in-out cursor-pointer hover:brightness-90;
  }

  label {
    @apply pb-2 text-[#999999];
  }

  img,
  video {
    @apply align-top pointer-events-none select-none;
  }

  select {
    @apply appearance-none;
  }

  table {
    @apply w-full border-collapse;
  }

  table td {
    @apply p-2 text-left align-top;
  }

  table ul {
    @apply list-disc pl-4;
  }

  table li {
    @apply text-base font-thin;
  }
}
